import React from "react";
import Img from "gatsby-image";
import { graphql, StaticQuery } from "gatsby";
import styled from "styled-components";
import { Flex, Box } from "reflexbox";
import BackgroundImage from "gatsby-background-image";
import { styleBackgroundImage } from "../../components/Section/section.style";

import { Row, Col } from "react-styled-flexboxgrid";
import { Primary, Secondary, Alternate } from "../../components/Section";
import { HrefButtonInverse, HrefButton } from "../../components/Button";

import ImgAuthor from "../../images/img-author.jpg";
import FMDLogoLegacy from "../../images/fmd-logo-legacy.jpg";

const BackgroundSection = ({ className, children }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "hero-booksmags.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={(data) => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid;
      return (
        <BackgroundImage
          Tag="section"
          className={className}
          fluid={imageData}
          backgroundColor={`#040e18`}
        >
          {children}
        </BackgroundImage>
      );
    }}
  />
);

const StyledBackgroundSection = styled(BackgroundSection)`
  ${styleBackgroundImage};
  background-position-y: 55%;
`;

const BooksMags = ({ data }) => (
  <section>
    <StyledBackgroundSection centered height={350}>
      <Row>
        <Col xs={12}>
          <h1>Forever My Daddy Grand Foundation</h1>
          <h3>Co-Parenting Rules!</h3>
        </Col>
      </Row>
    </StyledBackgroundSection>
    <Primary>
      <Row>
        <Col xs={12} md={6}>
          <h2>Forever My Daddy: Denied</h2>
          <p>
            Forever My Daddy: Denied is about Money, Power, and Legal Lessons
            Learned by a Daddy Blocked from his Children's Lives. Despite our
            modern epidemic of fatherlessness - and the myriad social problems
            it causes -- vast numbers of loving, caring, devoted fathers are
            blocked from their children's hearts and lives. It's called Parental
            Alienation Syndrome - a byproduct of broken marriages and a scourge
            of unnecessary emotional pain for kids and fathers alike, but it
            doesn't have to be this bad. There are powerful ways to cope.
          </p>
          <br />
          {/* <ButtonGhostInverse onClick={() => navigate("/touchless/samsonite")}>
            FMD:Denied Author page
          </ButtonGhostInverse> */}
        </Col>
        <Col xs={12} md={6}>
          <Img
            fluid={data.fmdbook.childImageSharp.fluid}
            alt="Forever My Daddy Book"
          />
        </Col>
      </Row>
      <Row style={{ marginTop: "3rem" }}>
        <Col xs={12}>
          <Box
            style={{
              backgroundColor: "#fafafa",
              borderRadius: "5px",
              padding: "1rem",
              display: "block",
            }}
          >
            <Row>
              <Col xs={12}>
                <Flex justifyContent={"center"}>
                  <h3>Visit Amazon and purchase one of Dr. J's books!</h3>
                </Flex>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                {" "}
                <Flex justifyContent={"center"}>
                  <HrefButton
                    href="https://www.amazon.com/~/e/B00E7791AC"
                    target="_blank"
                  >
                    Purchase Book
                  </HrefButton>
                </Flex>
              </Col>
            </Row>
          </Box>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Flex justifyContent={`center`}>
            <img
              src={FMDLogoLegacy}
              style={{ marginTop: "1rem", width: "150px" }}
              alt={`fmd legacy logo`}
            />
          </Flex>
        </Col>
      </Row>
    </Primary>

    <Secondary>
      <Row>
        <Col xs={12} md={6}>
          <h2>Sky's the limit</h2>
          <p>
            See our latest magazine and find out about the latest news and
            products.
          </p>
          <br />
          <HrefButtonInverse
            href="https://www.joynermd.com/skys-the-limit-magazine"
            target="_blank"
          >
            Read Magazine
          </HrefButtonInverse>
        </Col>
        <Col xs={12} md={6}>
          <Img
            fluid={data.mag.childImageSharp.fluid}
            alt="skys the limit magazine"
          />
        </Col>
      </Row>
    </Secondary>
    <Alternate>
      <Row>
        <Col xs={12} md={6}>
          <h2>Meet the Author</h2>
          <p>
            Dr. Michael Joyner, a Los Angeles native, graduated from Georgetown
            University School of Medicine in Washington, DC as a United States
            Army Health Scholarship Recipient. He completed his general surgery
            internship at Walter Reed Army Medical Center where he served active
            duty as a captain in the United States Army.
          </p>
          <p>
            After surgical internship, Dr. Joyner served as a US Army General
            Medical Officer at the Presidio of Monterey Army Health Clinic. He
            was the Primary Brigade Surgeon for the US Air Force 311th Training
            Squadron at the Defense Language Institute from 1996 to 2000.
          </p>
          <p>
            Dr. Joyner, a Loyola High School Alumnus, received his undergraduate
            training at UC Berkeley and Howard University. Dr. Joyner received
            his postgraduate training in General Surgery at Morehouse School of
            Medicine in Atlanta, Georgia. While serving as Chief General Surgery
            resident at Grady Memorial Hospital Dr. Joyner became very
            experienced in Trauma Surgery along with receiving The Avon Breast
            Cancer Center Award for service and care in surgical oncology and
            reconstructive surgery. He served as a Christin M. Kleinert Hand
            Fellow in 2005 and a visiting microsurgical scholar to Chang-Gung
            Memorial Hospital, Taipei, Taiwan in 2006.
          </p>
          <p>
            Prior to The Joyner Hand, PA, Dr. Joyner was a staff Hand and
            Microsurgeon for Kleinert, Kutz and Associates in Lexington,
            Kentucky. He was also a clinical instructor for the University of
            Louisville.
          </p>
        </Col>
        <Col xs={12} md={6}>
          <Flex justifyContent="center">
            <Box>
              <img
                src={ImgAuthor}
                alt="dr. joyner holding sign promoting book"
              />
            </Box>
          </Flex>
          <Flex justifyContent="center">
            <HrefButton
              href="https://www.authormichaeljoynermd.com/"
              target="_blank"
            >
              Visit Author Website
            </HrefButton>
          </Flex>
        </Col>
      </Row>
    </Alternate>
  </section>
);

export default BooksMags;
