import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";

import BooksMags from "../components/BooksMags";

const BooksMagsPage = ({ data }) => (
  <Layout>
    <BooksMags data={data} />
  </Layout>
);

export default BooksMagsPage;

export const query = graphql`
  query {
    fmdbook: file(relativePath: { eq: "fmd-book.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mag: file(relativePath: { eq: "sky-magazine.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
